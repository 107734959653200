<template>
  <div>
    <helloWorld />
    <div class="free-content">
      <div class="free-left">
        <el-collapse v-model="activeName">
          <el-collapse-item title="所属专辑" name="1">
            <el-radio v-for="(item, index) in albumList" :key="index" size="mini" v-model="queryParams.albumId" :label="item.id" @change="handleQuery" border style="margin-right:10px;margin-bottom:10px;margin-left:0">{{item.title}}</el-radio>
          </el-collapse-item>
          <el-collapse-item title="分类" name="2">
            <el-radio v-for="(item, ind) in typelist" :key="ind" size="mini" v-model="queryParams.typeId" label="1" @change="handleQuery" border style="margin-right:10px;margin-bottom:10px;margin-left:0">{{item.typeName}}</el-radio>
          </el-collapse-item>
          <!-- <el-collapse-item title="价格区间" name="3">
            <el-input placeholder="请输入区间最低价" size="mini"></el-input>
            <el-input placeholder="请输入区间最高价" style="margin-top:6px" size="mini"></el-input>
            <el-button size="mini" style="float:right;margin-top:6px;margin-bottom:6px">搜索</el-button>
          </el-collapse-item> -->
          <el-collapse-item title="数字藏品" name="4">
            <el-input v-model="queryParams.colName" placeholder="请输入数字藏品名称" size="mini" @keyup.enter.native="handleQuery"></el-input>
            <el-button size="mini" style="float:right;margin-top:6px;margin-bottom:6px" @click="handleQuery">搜索</el-button>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="phone-size">
        <div v-if="total == 0">
          <el-empty description="暂无藏品" style="margin-top:30px"></el-empty>
        </div>
        <div v-else class="free-right">
          <div class="free-item" v-for="(item, index) in list" :key="index" @click="todetail(item)">
            <div class="img-box" style="position:relative">
              <div class="status-tag" v-if="item.sale == true">在售</div>
              <div class="status-tag" v-if="item.sale == false">待售</div>
              <img :src="item.pic" alt="">
            </div>
            <p>{{item.colName}}</p>
            <span class="free-author" @click.stop="toUser(item)">{{item.creator.nickname}}</span>
            <div class="free-price">
                <div>￥{{item.price}}</div>
                <div>
                  <img v-if="item.creator.avatar!=''" class="fZZKFU-avatar" :src="item.avatar" alt="">
                  <img class="fZZKFU-avatar" v-else src="@/assets/avatar.jpg" alt="">
                </div>
              </div>
        </div>
        
      </div>
      <pagination
          v-show="total>0"
          :total="total"
          :page-sizes="[12, 24, 36, 48, 50]"
          :page.sync="queryParams.PageNum"
          :limit.sync="queryParams.PageSize"
          @pagination="getList"
        />
      </div>
      
    </div>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
import {getCollectList, getAlbum, getType, downImg} from '../../api/index'
import { mapGetters } from 'vuex'
export default {
  name: 'Index',
  components: {
    helloWorld
  },
  mounted() {
    if (!this.accessToken || this.accessToken == '') {
      this.$router.push('./')
      return
    }
    this.getList()
    this.getAlbumList()
    this.getTypeList()
  },
  computed: {
    ...mapGetters(['accessToken', 'userCode'])
  },
  data() {
    return {
      env: process.env.VUE_APP_BASE_API,
      total: 0,
      queryParams: {
        PageNum: 1,
        PageSize: 12,
        albumId: '',
        typeId: '',
        colName: ''
      },
      activeName: ['1', '2', '3', '4'],
      radio1: '1',
      radio2: '1',
      radio3: '1',
      radio4: '1',
      type: '',
      list: [],
      albumList: [],
      typelist: []
    }
  },
  methods: {
    toUser(item) {
      this.$router.push('../user?userCode=' + item.creator.userCode + '&&id='+item.creator.id)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.PageNum = 1;
      this.getList();
    },
    // 获取专辑列表
    getAlbumList() {
      getAlbum().then(res => {
        if (res.code == 0) {
          this.albumList = res.data.list
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取分类列表
    getTypeList() {
      getType().then(res => {
        if (res.code == 0) {
          this.typelist = res.data.list
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取藏品列表
    getList() {
      getCollectList(this.queryParams).then(res => {
        if (res.code == 0) {
          this.total = res.data.total
          this.list = res.data.list
          for (var s = 0; s < this.list.length; s++) {
            this.$set(this.list[s], 'sale', this.judgeTime(this.list[s].saleAt))
            if (this.list[s].saleMode != 0 ){
              this.list[s].pic = this.env+this.list[s].pic
            }else {
              this.getCollcetPic(s, this.list[s].pic)
            }
            if (this.list[s].creator.avatar != '') {
              this.list[s].avatar = this.env + this.list[s].creator.avatar
            }
          }
        } else{
          this.$message.error(res.message)
        }
      })
    },
    judgeTime (time)  {
      var strtime = time.replace("/-/g", "/");//时间转换
      //时间
      var date1 = new Date(strtime);
      //现在时间
      var date2 = new Date();
      //判断时间是否过期
      return date2 >= date1 ? true : false;
    },
    getCollcetPic(i, pic) {
      let a = {
        path: pic
      }
      downImg(a).then(res => {
        let blob = new Blob([res]);   // 返回的文件流数据
        let url = window.URL.createObjectURL(blob);  // 将他转化为路径
        this.list[i].pic = url;
      })
    },
    todetail(row) {
      this.$router.push('./detail?colCode='+row.colCode);
    }
  },
  watch: {
    $route () {
      this.$router.go(0)
    }
  },
}
</script>
<style scoped>
.status-tag {
  position: absolute;
  top: 0;
  right: 0px;
  background: linear-gradient(90deg,#D9F6FC,#CAF4EA);
  font-size: 12px;
  padding: 4px 6px;
  color: #666;
  border-radius: 2px;
}
.phone-size {
  width: 80%;
}
.free-price {
  padding: 4px 10px 4px 10px;
  text-shadow: 0px 20px 50px 0px rgba(2, 32, 77, 0.08);
  /** 文本1 */
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(2, 32, 78, 1);
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  font-family: "思源黑体";
}
.free-price img {
  width: 22px !important;
  border-radius: 50% !important;
}
.free-content {
  display: flex;
  max-width: 1200px;
  margin: auto;
}
.free-left {
  width: 18%;
  padding:30px 20px;
  border-right: 1px solid #f8f8f8;
}
.free-right {
  /* width: 80%; */
}
.free-right {
  padding: 30px 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  margin:auto;
}
.free-item {
  margin:  10px;
  border: 1px solid #eee;
  border-radius: 8px;
}
.img-box {
  width: 100%;
  max-height: 250px;
  text-align: center;
}
.img-box img{
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  height: 200px;
}
.free-item img{
  border-radius: 8px;
}
.free-item p {
      font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1d202b;
    letter-spacing: 1px;
    cursor: pointer;
    padding: 4px 10px;
    display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1; /*超出几行后省略号*/
overflow: hidden;
}
.free-author {
  font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9b9b9b;
    padding: 4px;
    padding-left: 10px;
    cursor: pointer;
}
.look {
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f8f8f8;
  margin-top: 8px;
}
.look-left {
  display: flex;
  align-items: center;
}
.look-left img {
  width: 25px;
}
.look-left span {
  color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
}
.def-buybtn {
  width: 90px;
    height: 30px;
    font-size: 10px;
    border-radius: 3px;
    color: #fff;
    background: rgba(2, 32, 78, 1);
    text-align: center;
    line-height: 30px;
}
@media screen and (max-width: 720px){
  .free-right{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .free-left {
    display: none;
  }
  .free-right{
    margin: auto;
  }
}
@media only screen and (min-width: 720px) and (max-width: 900px){
  .free-right{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
<style>
.free-left .el-radio__inner {
  display: none;
}
.free-left .el-radio {
  margin-right: 0;
}
</style>